import { Injectable } from "@angular/core";
import { Borders, Fill, Row, Style, Workbook, Worksheet } from "exceljs";
import { saveAs } from "file-saver";

import { ColumnHeader, ColumnType } from "./column-header";

interface RowStyles extends Style {
    height: number;
    numFmt: string;
}

const MAX_COLUMN_LENGTH = 50;
const DATE_TIME_LENGTH = 20;
const DATE_LENGTH = 10;
const COLUMN_LENGTH_ADJUSTMENT = 2;

const XLSX_MIME_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const XLSX_SHEET_NAME = "Export";

const WHITE_COLOR = "FFFFFF";
const BLACK_COLOR = "000000";
const GREY_COLOR = "CCCCCC";
const LIGHT_GREY_COLOR = "DDDDDD";
const LIGHT_BLUE_COLOR = "00AFDB";
const DARK_BLUE_COLOR = "00334E";

const TINY_CELL_WIDTH = 2;

const TINY_CELL_HEIGHT = 10;
const MEDIUM_CELL_HEIGHT = 20;
const LARGE_CELL_HEIGHT = 40;

@Injectable({
    providedIn: "root"
})
export class ExcelExportService {
    export(filename: string, headers: ColumnHeader[], data: any[], sheetHeader: string) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(XLSX_SHEET_NAME);

        const dataRows = data.map((row) => Object.keys(row).map((key) => row[key] ?? ""));
        const maxLengths = this.getMaxLengths(dataRows, headers);

        this.fillWorksheet(worksheet, headers, maxLengths, dataRows, sheetHeader);

        workbook.xlsx
            .writeBuffer()
            .then((result) => saveAs(new Blob([result], { type: XLSX_MIME_TYPE }), filename))
            .catch((error) => console.error("Error occurred while exporting grid rows:", error));
    }

    fillWorksheet(ws: Worksheet, headers: ColumnHeader[], maxLengths: number[], dataRows: any[][], sheetHeader: string) {
        const emptyStrsArr = new Array(headers.length).fill("");
        const tableHeaders = headers.map((h) => h.caption);

        const topRow = this.addTopOrBottomRow(ws, emptyStrsArr);
        this.addSheetHeader(ws, tableHeaders, sheetHeader);
        const tableHeadersRow = this.addTableHeadersRow(ws, tableHeaders);
        this.addAutoFilterToRow(ws, tableHeadersRow);
        const types = headers.map(({ type }) => type);
        // adds body of table
        dataRows.forEach((dataRow) => {
            this.addRow(ws, dataRow, { alignment: { wrapText: true }, border: this.getDefaultBorderParams(BLACK_COLOR) }, types);
        });

        this.adjustColumnWidth(ws, maxLengths);
        this.formatSideEdges(ws, topRow.model?.max ?? 1);
        this.addTopOrBottomRow(ws, emptyStrsArr);
    }

    private addTableHeadersRow(ws: Worksheet, tableHeaders: string[]) {
        return this.addRow(ws, tableHeaders, {
            border: this.getDefaultBorderParams(BLACK_COLOR),
            fill: this.getDefaultFillParams(LIGHT_GREY_COLOR),
            font: { bold: true, color: { argb: LIGHT_BLUE_COLOR } }
        });
    }

    private addTopOrBottomRow(ws: Worksheet, arr: string[]) {
        return this.addRow(ws, arr, {
            border: this.getDefaultBorderParams(GREY_COLOR),
            height: TINY_CELL_HEIGHT,
            fill: this.getDefaultFillParams(GREY_COLOR)
        });
    }

    private formatSideEdges(ws: Worksheet, lastCellIdx: number) {
        [ws.getColumn(1), ws.getColumn(lastCellIdx)].forEach((column) => {
            column.width = TINY_CELL_WIDTH;

            column.eachCell({ includeEmpty: false }, (cell) => {
                cell.fill = this.getDefaultFillParams(GREY_COLOR);
                cell.border = this.getDefaultBorderParams(GREY_COLOR);
            });
        });
    }

    private addRow(ws: Worksheet, data: any[], { alignment, border, fill, font, height }: Partial<RowStyles> = {}, types?: ColumnType[]) {
        // we need empty strings to add left and right columns since
        // exceljs doesn't have functionality for adding columns
        data = ["", ...data, ""];
        const row = ws.addRow(data);

        if (alignment) {
            row.eachCell((cell) => (cell.alignment = alignment));
        }
        if (border) {
            row.eachCell((cell) => (cell.border = border));
        }
        if (fill) {
            row.eachCell((cell) => (cell.fill = fill));
        }
        if (font) {
            row.eachCell((cell) => (cell.font = font));
        }
        if (height) {
            row.height = height;
        }

        if (types) {
            types = ["string", ...types, "string"];
            row.eachCell((cell, i) => {
                const fmt = this.getNumFmt((types as ColumnType[])[i - 1]);
                if (fmt) {
                    cell.numFmt = fmt;
                }
            });
        }

        return row;
    }

    private addSheetHeader(ws: Worksheet, headers: string[], sheetHeader: string) {
        const emptyStrsArr = new Array(headers.length).fill("");

        this.addRow(ws, emptyStrsArr, { height: LARGE_CELL_HEIGHT, border: this.getDefaultBorderParams(WHITE_COLOR) });
        this.addRow(ws, emptyStrsArr, { fill: this.getDefaultFillParams(DARK_BLUE_COLOR) });

        const sheetHeaderRow = this.addRow(ws, [sheetHeader, ...new Array(headers.length - 1).fill("")], {
            alignment: { vertical: "middle", horizontal: "left" },
            fill: this.getDefaultFillParams(DARK_BLUE_COLOR),
            font: { bold: true, size: 30, color: { argb: WHITE_COLOR } },
            height: LARGE_CELL_HEIGHT
        });

        if (sheetHeaderRow.model?.number && sheetHeaderRow.model.max && sheetHeaderRow.model.min) {
            const {
                model: { number, min, max }
            } = sheetHeaderRow;
            ws.mergeCells(number, min + 1, number, max - 1);
        }

        this.addRow(ws, emptyStrsArr, { fill: this.getDefaultFillParams(DARK_BLUE_COLOR) });
        const dateRow = this.addRow(ws, emptyStrsArr, {
            alignment: { vertical: "middle", horizontal: "right" },
            fill: this.getDefaultFillParams(LIGHT_BLUE_COLOR),
            height: MEDIUM_CELL_HEIGHT,
            font: { color: { argb: WHITE_COLOR }, bold: true }
        });
        const dateCell = dateRow.getCell(dateRow.cellCount - 1);
        dateCell.merge(dateRow.getCell(dateRow.cellCount - 2));
        dateCell.value = this.getCurrentDateForExcel();
        dateCell.numFmt = "dd mmmm yyyy";
        this.addRow(ws, emptyStrsArr, { height: MEDIUM_CELL_HEIGHT, border: this.getDefaultBorderParams(WHITE_COLOR) });
    }

    private adjustColumnWidth(ws: Worksheet, maxLengths: number[], startingColumn = 1) {
        maxLengths.forEach((value, index) => {
            const column = ws.columns[index + startingColumn];
            column.width = Math.min(value + COLUMN_LENGTH_ADJUSTMENT, MAX_COLUMN_LENGTH);
        });
    }

    private getNumFmt(type: string) {
        if (type === "number") {
            return "#,##0.00";
        }
        if (type === "integer") {
            return "#0";
        }
        if (type === "date") {
            return "dd/mm/yyyy";
        }
        if (type === "datetime") {
            return "dd/mm/yyyy hh:mm";
        }
        return;
    }

    private getCurrentDateForExcel() {
        const date = new Date();
        const result = new Date();
        result.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        result.setUTCHours(0, 0, 0, 0);
        return result;
    }

    private getMaxLengths(dataRows: any[][], headers: ColumnHeader[]) {
        const maxLengths = headers.map((h) => h.caption.length);

        dataRows.forEach((dataRow) => {
            dataRow.forEach((value, index) => {
                if (headers[index].type === "datetime") {
                    maxLengths[index] = Math.max(maxLengths[index], DATE_TIME_LENGTH);
                    return;
                }

                if (headers[index].type === "date") {
                    maxLengths[index] = Math.max(maxLengths[index], DATE_LENGTH);
                    return;
                }

                maxLengths[index] = Math.max(maxLengths[index], ...`${value}`.split("\r\n").map((x) => x.length));
            });
        });

        return maxLengths;
    }

    private addAutoFilterToRow(ws: Worksheet, { model, number }: Row) {
        if (model?.min && model?.max) {
            ws.autoFilter = { from: { row: number, column: model.min + 1 }, to: { row: number, column: model.max - 1 } };
        }
    }

    private getDefaultBorderParams(argb: string): Partial<Borders> {
        return {
            top: { style: "thin", color: { argb } },
            left: { style: "thin", color: { argb } },
            bottom: { style: "thin", color: { argb } },
            right: { style: "thin", color: { argb } }
        };
    }

    private getDefaultFillParams(argb: string): Fill {
        return { type: "pattern", pattern: "solid", fgColor: { argb } };
    }
}
